import * as React from "react";
import { useState } from "react";
import LayoutService from "../../templates/LayoutService";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import BuyFourHourBtn from "../../components/buttons/BuyFourHourBtn";
import { Link } from "gatsby";
import "../../styles/components/titles.module.scss";
import "../../styles/components/header.module.scss";
import "../../styles/components/content.module.scss";
import "../../styles/components/contenttoggles.scss";
import { imgBox } from "../../styles/components/boxes.module.scss";
import {
  bannerBlock,
  bannerBlockHeadline,
  bannerBlockTagline,
  bannerBlockTitleSm,
  pageContentBlock,
  pageServiceDivider,
  serviceInfoBlock,
  serviceInfoBtns,
  pageCalloutBlock,
  pageContentMedia,
} from "../../styles/components/blocks.module.scss";
import { hideBlockSM } from "../../styles/components/helpers.module.scss";
import FourHourInfo from "../../components/blocks/FourHourInfo";
import GalleryFourHour from "../../components/galleries/GalleryFourHour";
import { btn } from "../../styles/components/buttons.module.scss";
const FourHourPage = () => {
  const [isActive, setActive] = useState(true);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <LayoutService>
      <Helmet>
        <title>
          4-Hour Private Party Boat Charter | Tampa Bay Boating Adventures
        </title>
        <meta
          name="description"
          content="“”This is how Tampa parties!” See why we’re Tampa’s highest-rated party boat charter. Up to six guests. Book today."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className="container">
        <div className="row">
          <div className="col-xxs-12">
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="Friends talking on a party boat in Tampa Bay, FL."
                  src="../../images/banners/friends-talking-on-party-boat.jpg"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>
                  4-Hour Private Party Boat Charter in Tampa Bay
                </h1>
                <span className={bannerBlockTitleSm}>
                  Party in <br className={hideBlockSM} />
                  Tampa Bay, Florida
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div className="row">
          <div className="col-xxs-12">
            <GalleryFourHour />
            <div className={serviceInfoBlock}>
              <h2>4-Hour Party Boat Package Summary</h2>
              <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",
                
                  justifyContent: "flex-start",
                }}
              >
                <BuyFourHourBtn />

                <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>

                {/* <a href="#description" className={btn} onClick={toggleClass}>{isActive ? "Hide Description" : "View Description"}</a> */}
              </div>
              <FourHourInfo />
            </div>
            <div
              id="pageContentContainer"
              className={isActive ? "pageContentOpen" : "noClass"}
            >
              <div className="anchor" id="description"></div>
              <div className={pageContentBlock}>
                <hr className={pageServiceDivider} />
                <h2>
                  Explore the waters of Tampa Bay on our 4-hour private party
                  boat tour.
                </h2>
                <h3>
                  Some places we can take you on our 4-hour private party boat
                  include Beer Can Island, Tampa Riverwalk, sandbars, private
                  islands, and more!
                </h3>
                <div className={pageContentMedia}>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Zach living the life on a party boat near Tampa Bay, FL."
                      src="../../images/banners/zach-living-the-life.jpg"
                    />
                  </div>
                </div>
                <p>
                  Picture this—you and your friends drinking your favorite
                  beverages, jumping into the ocean, and partying with other
                  college-aged people on a sandbar with your favorite music
                  playing in the background. That vision can become a reality
                  when you book a 4-hour private party boat charter with Tampa
                  Bay Boating Adventures. Our private party boat can take you to
                  popular party destinations like Beer Can Island, Sparkman
                  Wharf, and Tampa Riverwalk, or even drop anchor at some
                  incredible sandbars! You can bring any alcohol and snacks you
                  want to make sure you have lots of fuel to keep the party
                  going.
                </p>
                <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",
                
                  justifyContent: "flex-start",
                }}
              >
                  <BuyFourHourBtn />
                  <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>
                </div>
                <hr />
                <h2>
                  Our 4-hour private party boat is perfect for a morning or
                  afternoon of fun!
                </h2>
                <p>
                  At Tampa Bay Boating Adventures, we understand that you can’t
                  always spend an entire day out on the water, which is why we
                  offer 4-hour private party boat charters in addition to our
                  6-hour and 8-hour trips. You can book our 4-hour charter for
                  either the afternoon or the morning—whatever works best for
                  you!
                </p>
                <h3>Morning Charter</h3>
                <p>
                  Got a concert or event later in the afternoon? What better way
                  to pregame than with a private party boat! Start your day off
                  right by partying in the sun with your friends while jamming
                  out to your favorite music. We’ll drop you off as the
                  afternoon approaches, and with a nice buzz and a fresh tan,
                  you’ll be ready for whatever the rest of the day brings!
                </p>
                <h3>Afternoon Charter</h3>
                <p>
                  Whether you just finished studying or sleeping off your
                  hangover from the night before, there’s no better way to
                  kickstart the second half of your day than on a party boat
                  with your closest friends. Spend the afternoon letting loose
                  with your friends while exploring the beautiful Tampa Bay
                  waters.
                </p>
                <div className={pageCalloutBlock}>
                  <p>
                    You can even bring your dogs on our party boat (as long as
                    they are well behaved) so they don’t have to miss out on any
                    of the fun!
                  </p>
                </div>
                <hr />
                <h2>
                  On Our Party Boat – You Bring the Booze, We’ll Bring the
                  Basics
                </h2>
                <p>
                  We don’t serve alcohol on our party boat. However, we do allow
                  you to bring your own alcohol. That’s right! No paying 10
                  bucks a drink and having to tip each time. Just pack a cooler
                  with your favorite adult beverages and bring it along for the
                  ride! All we ask is that you drink responsibly and that you
                  know your limits. After all, no one likes getting flagged for
                  a party foul.
                </p>
                <p>
                  We’ll supply water and snacks to make sure you stay hydrated
                  and have something in your belly to soak up the booze. You can
                  also bring whatever kind of food you want, so feel free to
                  bring some sandwiches along for the ride.
                </p>
                <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",
                
                  justifyContent: "flex-start",
                }}
              >
                  <BuyFourHourBtn />
                  <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>
                </div>
                <hr />
                <h2>
                  Our 4-Hour Charter Can Take You to Beer Can Island, Tampa
                  Riverwalk, Sandbars, &amp; More!
                </h2>
                <p>
                  4 hours is plenty of time for you and your friends to explore
                  the beautiful party spots we’re lucky enough to live right
                  next to. We can take you to Beer Can Island, which is known
                  for its raucous party atmosphere. Or, if you’d rather pull up
                  to Tampa Riverwalk and grab some drinks, we can do that too!
                  Our local captains know all the best sandbars and islands
                  where we can anchor down so you and your friends can get wet
                  and wild. We don’t have any set itineraries, so just choose
                  where you want to go and we’ll happily oblige.
                </p>
                <hr />
                <h2>
                  Fill out our form or send us an email to book your 4-hour
                  party boat charter today!
                </h2>
                <p>
                  Ready to have the best 4 hours of your life? If so, it’s time
                  to book a 4-hour party boat charter with Tampa Bay Boating
                  Adventures. We book up fast, so make sure you reserve your
                  spot as soon as possible so you can get the day you want. Just
                  fill out <Link to="/contact">our submission form</Link> or{" "}
                  <a href="mailto:ahoy@tampabayboatingadventures.com">
                    send us an email
                  </a>{" "}
                  and we’ll get back to you as soon as we can.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </LayoutService>
  );
};

export default FourHourPage;
